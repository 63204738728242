import React, {useState} from 'react';
import { AppBar, Box, Stack, Toolbar, IconButton, Typography, Menu, Button, MenuItem, Link } from '@mui/material';
import {Auth} from 'aws-amplify';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

const pages = [{text: 'Style Quiz', link: '/StyleQuiz'},
               {text: 'Our Community', link: '/Community'},
               {text: 'Social Impact', link: '/SocialImpact'},
               {text: 'Contact Us', link: '/ContactUs'}];

const Navbar = (props) => {
    const [anchorNavMenu, setAnchorNavMenu] = useState(null);
    const [anchorUserMenu, setAnchorUserMenu] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorNavMenu(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorNavMenu(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorUserMenu(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorUserMenu(null);
    };

    const _signout = async () => {
        Auth.signOut();
        props.updateUser();
    }

    return (
        <AppBar position='sticky'>
            <Toolbar disableGutters sx={{ my: 1, justifyContent: 'space-between'}}>
                <Link
                    underline="none"
                    href="/"
                >
                    {/* <Typography variant='h2' sx={{mx: 3, fontSize: {xs: '2.5rem', sm:'3rem'}}}> IBELIEVE.AI </Typography> */}
                    <img
                        src={'images/logo_smaller.png'}
                        style={{
                            maxHeight: '5rem',
                            maxWidth: '100%',
                            paddingLeft: '3rem'
                        }}
                        alt='iBelieve.ai'
                    />
                </Link>

                <Stack direction='row'>
                    {/* Desktop Nav Links */}
                    <Box sx={{display: { xs: 'none', lg: 'flex' }}}>
                        {pages.map((page) => (
                            <Button
                                key={page.text}
                                href={page.link}
                                sx={{m: 'auto', px:1, display: 'block'}}
                                style={{textTransform: 'none'}}
                            >
                            <Typography variant='h5' sx={{mx: 3, fontSize: {xs:'1rem', lg:'1.6rem'}}}> {page.text} </Typography>
                            </Button>
                        ))}
                    </Box>
               
                    {/* Mobile Nav Links */}
                    <Box sx={{my: 1, display: { xs: 'flex', lg: 'none' } }}>
                        <IconButton
                            size='large'
                            color='primary'
                            onClick={handleOpenNavMenu}
                        >
                            <MenuIcon style={{fontSize: '2rem', color:'#411405' }}/>
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorNavMenu}
                            open={Boolean(anchorNavMenu)}
                            onClose={handleCloseNavMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem component={Link} key={page.text} onClick={handleCloseNavMenu} href={page.link}>
                                    <Typography variant='body' sx={{ typography: { xs: 'body1', lg: 'body3' } }}>{page.text}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                
                    <IconButton
                        size='large'
                        key='user'
                        color='primary'
                        type='button'
                        onClick={props.user ? handleOpenUserMenu : null}
                        href={props.user ? null : '/login'}
                        sx={{my: 'auto', mr: 5, ml: 2, display: 'block'}}
                    >
                        <PersonIcon style={{fontSize: '2.2rem', color:'#411405' }}/>
                    </IconButton>

                    {props.user ?
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorUserMenu}
                            open={Boolean(anchorUserMenu)}
                            onClose={handleCloseUserMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem key='Name'>
                                <Typography>{props.user.attributes.name}</Typography>
                            </MenuItem>
                            <MenuItem component={Link} key='Sign Out' onClick={_signout}>
                                <Typography variant='body'>Sign Out</Typography>
                            </MenuItem>
                        </Menu> 
                    : <></> }
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;