import React, {useState} from 'react';
import {Grid, Stack, Box, Button, Typography, LinearProgress} from '@mui/material';
import {Card, CardActions, CardContent, CardMedia} from '@mui/material';
import { withStyles } from '@mui/styles';

import QuizSlide from '../components/QuizSlide';

const quizQuestions = [
    {
        _id: 'begin',
        header: 'Start your style journey!',
        subheader: '',
        choices: []
    },
    {
        _id: 'event',
        header: 'What kind of event/occasion are you styling for?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Business', _id: 'business'}, {image: '', text: 'Formal', _id: 'formal'}, {image: '', text: 'Casual', _id: 'casual'} ]
    },
    {
        _id: 'style',
        header: 'What is your typical dressing style?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Professional', _id: 'professional'}, {image: '', text: 'Preppy', _id: 'preppy'}, {image: '', text: 'Chic', _id: 'chic'}, {image: '', text: 'Athleisure', _id: 'athleisure'}, {image: '', text: 'Minimalistic', _id: 'minimalistic'}, {image: '', text: 'Elegant', _id: 'elegant'}, ]
    },
    {
        _id: 'weather',
        header: 'What kind of weather are you styling for?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Spring', _id: 'spring'}, {image: '', text: 'Summer', _id: 'summer'}, {image: '', text: 'Fall', _id: 'fall'}, {image: '', text: 'Winter', _id: 'winter'} ]
    },
    {
        _id: 'weather_condition',
        header: 'What kind of weather conditions are you styling for?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Humid', _id: 'humid'}, {image: '', text: 'Windy', _id: 'windy'}, {image: '', text: 'Dry', _id: 'dry'}, {image: '', text: 'Rainy', _id: 'rainy'} ]
    },
    {
        _id: 'measurement',
        header: 'What are your measurements?',
        subheader: '',
        choices: [ {image: '', text: 'Height', _id: 'height'}, {image: '', text: 'Bust', _id: 'bust'}, {image: '', text: 'Waist', _id: 'waist'}, {image: '', text: 'Hip', _id: 'hip'} ]
    },
    {
        _id: 'skin_tone',
        header: 'Skin Tone',
        subheader: 'Please select only ONE.',
        choices: [ {image: '', text: 'Skintone1', _id: 'skintone1'}, {image: '', text: 'Skintone2', _id: 'skintone2'}, {image: '', text: 'Skintone3', _id: 'skintone3'}]
    },
    {
        _id: 'under_tone',
        header: 'Skin Tone - Undertones',
        subheader: 'Choose ONE. What are your undertones?',
        choices: [ {image: '', text: 'Undertone1', _id: 'undertone1'}, {image: '', text: 'Undertone2', _id: 'undertone2'}, {image: '', text: 'Undertone3', _id: 'undertone3'}]
    },
    {
        _id: 'brands',
        header: 'What brands do you typically buy from?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Gap', _id: 'gap'}, {image: '', text: 'White Black', _id: 'white_black'}, {image: '', text: 'Zara', _id: 'zara'}, {image: '', text: 'Ann Taylor', _id: 'ann_taylor'},
                   {image: '', text: 'Loft', _id: 'loft'}, {image: '', text: 'H&M', _id: 'hm'}, {image: '', text: 'Banana Republic', _id: 'br'}, {image: '', text: 'Antropology', _id: 'antropology'}]
    },
    {
        _id: 'top_style',
        header: 'Style- How do you like your tops to fit and look?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Fitted', _id: 'fitted'}, {image: '', text: 'Loose', _id: 'loose'}, {image: '', text: 'Short', _id: 'short'}, {image: '', text: 'Long', _id: 'long'}, {image: '', text: 'Peplum', _id: 'peplum'} ]
    },
    {
        _id: 'bottom_style',
        header: 'Style- How do you like your pants/jeans to fit and look?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Strapless', _id: 'strapless'}, {image: '', text: 'Sleeve Less', _id: 'sleeve_less'}, {image: '', text: 'Short Sleeve', _id: 'short_sleeve'}, {image: '', text: 'Long Sleeve', _id: 'long_sleeve'},
         {image: '', text: 'Scoop', _id: 'scoop'}, {image: '', text: 'High', _id: 'high'}, {image: '', text: 'Plunging', _id: 'plunging'}, {image: '', text: 'V-Neck', _id: 'v_neck'}]
    },
    {
        _id: 'neckline_sleeves',
        header: 'Style- How do you like your sleeves/neckline to fit and look?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'High Rise', _id: 'high_rise'}, {image: '', text: 'Low Rise', _id: 'low_rise'}, {image: '', text: 'Skinny', _id: 'skinny'}, {image: '', text: 'Loose', _id: 'loose'},
          {image: '', text: 'Distressed', _id: 'distressed'}, {image: '', text: 'Bell Bottom', _id: 'bell_bottom'}]
    },
    {
        _id: 'dress_skirt_fit',
        header: 'Style- How do you like your skirts/dresses to fit and look?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Short', _id: 'short'}, {image: '', text: 'Mid', _id: 'mid'}, {image: '', text: 'Long', _id: 'long'}, {image: '', text: 'Fitted', _id: 'fitted'},
          {image: '', text: 'Loose', _id: 'loose'}, {image: '', text: 'Flared', _id: 'flared'}]
    },
    {
        _id: 'shoes',
        header: 'Style- What kind of shoes are you looking for?',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Flats', _id: 'flats'}, {image: '', text: 'Sneakers', _id: 'sneakers'}, {image: '', text: 'Pumps', _id: 'pumps'}, {image: '', text: 'Boots', _id: 'boots'},
          {image: '', text: 'Open Toe', _id: 'open_toe'}, {image: '', text: 'Sandals', _id: 'sandals'}]
    },
    {
        _id: 'color_palette',
        header: 'Color Palette',
        subheader: 'Please select up to 10. This will allow us to understand your desired color palette.',
        choices: [ {image: '', text: 'Spring', _id: 'spring'}, {image: '', text: 'Summer', _id: 'summer'}, {image: '', text: 'Fall', _id: 'fall'}, {image: '', text: 'Winter', _id: 'winter'} ]
    },
    {
        _id: 'patterns',
        header: 'Patterns',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Solid', _id: 'solid'}, {image: '', text: 'Stripes', _id: 'stripes'}, {image: '', text: 'Polka Dot', _id: 'polka_dot'}, {image: '', text: 'Floral', _id: 'floral'} ]
    },
    {
        _id: 'rating',
        header: 'Rating',
        subheader: 'Choose all that apply',
        choices: [ {image: '', text: 'Solid', _id: 'solid'}, {image: '', text: 'Stripes', _id: 'stripes'}, {image: '', text: 'Polka Dot', _id: 'polka_dot'}, {image: '', text: 'Floral', _id: 'floral'} ]
    }
];

const backgroundImages = [
    'images/style_quiz/background1_2.png',
    'images/style_quiz/background2_2.png',
    'images/style_quiz/background2_2.png',
    'images/style_quiz/background3_2.png',
    'images/style_quiz/background4_2.png',
    'images/style_quiz/background5_2.png',
    'images/style_quiz/background6_2.png',
    'images/style_quiz/background6_2.png',
    'images/style_quiz/background7_2.png',
    'images/style_quiz/background8_2.png',
    'images/style_quiz/background9_2.png',
    'images/style_quiz/background10_2.png',
    'images/style_quiz/background11_2.png',
    'images/style_quiz/background12_2.png',
    'images/style_quiz/background13_2.png',
    'images/style_quiz/background14_2.png'
]

const defaultStyleData = {};
for (let question of quizQuestions) {
    defaultStyleData[question._id] = {}
    for (let choice of question.choices) {
        defaultStyleData[question._id][choice._id] = false;
    }
}

const StyleQuiz = () => {
{/*
    const [currentQuestionIndex, setCurrentQuestion] = useState(0);
    const [styleData, setStyleData] = useState(defaultStyleData);

    const _setStyleDataForQuestion = async (questionID, questionData) => {
        setStyleData({...styleData, [questionID]: questionData})
        console.log(styleData);
    }

     return (
        <>
        <Box
            sx={{
                position: 'fixed',
                alignSelf: 'center',
                width: '100%',
                height: '100%',
                zIndex: '-1',
                backgroundImage: 'url("' + backgroundImages[currentQuestionIndex % backgroundImages.length] + '")',
                //backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
            }}
            alignItems= 'center'
            maxWidth= '100%'
            maxHeight= '100%'
        >
        </Box>

        <Stack 
            my={5}
            mx={{xs: 5, sm: 10, md: 15, lg: 60}}
            spacing={3}
            sx={{
                textAlign: 'left',
                justifyContent: 'center'
            }}
        >
            <CustomLinearProgress 
                sx={{height: 15, borderRadius: 5}}
                variant='determinate'
                value={currentQuestionIndex/quizQuestions.length * 100}
            />

            <QuizSlide 
                question={quizQuestions[currentQuestionIndex]} 
                questionData={styleData[quizQuestions[currentQuestionIndex]._id]} 
                setQuestionData={(questionData) => _setStyleDataForQuestion(quizQuestions[currentQuestionIndex]._id, questionData)}
            />

            {currentQuestionIndex == 0 ?
                <Stack direction='row' sx={{xs: 5, lg: 15,}} spacing={5} padding={5}>
                    <Button
                        size= 'small'
                        variant='bold'
                        sx={{fontSize: {xs:'0.5rem', lg:'1rem'}, alignSelf: 'center'}}
                        onClick={() => setCurrentQuestion(currentQuestionIndex + 1)}
                        style= {{
                             backgroundColor: '#411405',
                             color: 'white',
                             fontSize: '1rem',
                             '&:hover': {
                                 backgroundColor: '#411405',
                             },
                         }}
                    >
                    Begin
                    </Button>
                </Stack>
               : <></>
            }
            <Stack justifyContent='center' direction='row' sx={{xs: 5, lg: 15,}} spacing={5} padding={5}>
                {currentQuestionIndex > 0 ?
                    <Button
                        size= 'small'
                        variant='bold'
                        sx={{fontSize: {xs:'0.5rem', lg:'1rem'}, alignSelf: 'center'}}
                        onClick={() => setCurrentQuestion(currentQuestionIndex - 1)}
                        style= {{
                             backgroundColor: '#DE9F90',
                             color: 'white',
                             fontSize: '1rem',
                             '&:hover': {
                                 backgroundColor: '#DE9F90',
                             },
                         }}
                    >
                        Back
                    </Button>
                    : <></>
                }

                {currentQuestionIndex>0 && currentQuestionIndex < quizQuestions.length - 1 ?
                    <Button
                        size= 'small'
                        variant='bold'
                        sx={{fontSize: {xs:'0.5rem', lg:'1rem'}, alignSelf: 'center'}}
                        onClick={() => setCurrentQuestion(currentQuestionIndex + 1)}
                        style= {{
                             backgroundColor: '#DE9F90',
                             color: 'white',
                             fontSize: '1rem',
                             '&:hover': {
                                 backgroundColor: '#DE9F90',
                             },
                         }}
                    >
                        Next Step
                    </Button>
                    : <></>
                }

                {currentQuestionIndex == quizQuestions.length -1 ?
                    <Button
                        size= 'small'
                        variant='bold'
                        sx={{fontSize: {xs:'0.5rem', lg:'1rem'}, alignSelf: 'center'}}
                        onClick={() => setCurrentQuestion(currentQuestionIndex + 1)}
                        style= {{
                             backgroundColor: '#DE9F90',
                             color: 'white',
                             fontSize: '1rem',
                             '&:hover': {
                                 backgroundColor: '#DE9F90',
                             },
                         }}
                    >
                        Get Recommendations
                    </Button>
                    : <></>
                }
            </Stack>
        </Stack>
        </>
    );
}

const CustomLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#ECDAC4',
    },
    barColorPrimary: {
      backgroundColor: '#DE9F90',
    },
})(LinearProgress);
}
*/}

    return (
            <>
            <Grid container columnSpacing={10} rowSpacing={2} px={2} direction="row" alignItems="center" sx={{bgcolor: '#FFFFFF'}}>
                <Grid item xs={12} pb={5}>
                    <CustomCard image={{url:'images/ComingSoon.png'}} />
                </Grid>
            </Grid>
            </>
    );
    }


export default StyleQuiz;

const CustomCard = (props) => {
    return (
        <Card sx={{ height: '1100px'}}>
            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    height='1100px'
                    sx={!props.image.hasOwnProperty('padding') || props.image.padding !== false ?
                            {p: 1, borderRadius: '1px'}
                        :
                            {}}
                    image={props.image.url}
                />
            : null}

            <CardContent>
                {props.hasOwnProperty('header') ?
                    <Typography variant='h4'>
                        {props.header}
                    </Typography>
                : null}
                {props.hasOwnProperty('body') ?
                    <Typography variant='body'>
                        {props.body}
                    </Typography>
                : null}
            </CardContent>

            {props.hasOwnProperty('action') ?
                <CardActions px='5'>
                    <Button
                        sx={{color: '#C82D48', fontWeight: 'bold'}}
                        href={props.action.link}
                    >
                        {props.action.text}
                    </Button>
                </CardActions>
            : null}
        </Card>
    );
}