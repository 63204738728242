import { createTheme } from '@mui/material/styles';

let _backgroundColor = '#ECDAC4'
let _textColor = '#411405' //'#662008'
let _accentColor = '#C82D48'
let _font = 'Franklin Gothic Medium'

export const theme = createTheme({
    palette: {
        primary: {main: '#000'},
    },
    typography: {
        h2: {
            fontFamily: _font,
            color: _textColor,
        },
        h3: {
            fontFamily: _font,
            color: _textColor,
        },
        h4: {
            fontFamily: _font,
            color: _textColor,
        },
        h5: {
            fontFamily: _font,
            color: _textColor,
        },
        body: {
            fontFamily: _font,
            fontSize: '1.1rem',
            color: _textColor,
        },
        body07: {
            fontFamily: _font,
            fontSize: '.7rem',
            color: _textColor,
        },
        body09: {
            fontFamily: _font,
            fontSize: '.8rem',
            color: _textColor,
        },
        body13: {
            fontFamily: _font,
            fontSize: '1.2rem',
            color: _textColor,
        },
        body16: {
            fontFamily: _font,
            fontSize: '1.6rem',
            color: _textColor,
        },
        body18: {
            fontFamily: _font,
            fontSize: '1.8rem',
            color: _textColor,
        },
        body3: {
            fontFamily: _font,
            fontSize: '2.1rem',
            color: _textColor,
        },
        bodyh: {
            fontFamily: _font,
            fontSize: '1rem',
            color: _textColor,
        },
        bodyh1: {
            fontFamily: _font,
            fontSize: '1.6rem',
            color: _textColor,
        },
        bodyh2: {
            fontFamily: _font,
            fontSize: '2rem',
            color: _textColor,
        },
        bodyh23: {
            fontFamily: _font,
            fontSize: '2.5rem',
            color: _textColor,
        },
        bodyh3: {
            fontFamily: _font,
            fontSize: '3rem',
            color: _textColor,
        },
        bodyh33: {
            fontFamily: _font,
            fontSize: '3.3rem',
            color: _textColor,
        },
        bodyl1: {
            fontFamily: _font,
            fontSize: '1rem',
            color: _accentColor,
        },
        bodyl18: {
         fontFamily: _font,
         fontSize: '1.3rem',
         color: _accentColor,
        },
        bodyl2: {
            fontFamily: _font,
            fontSize: '2rem',
            color: _accentColor,
        },
        bodyl23: {
          fontFamily: _font,
          fontSize: '2.3rem',
          color: _accentColor,
        },
        bodyh4: {
            fontFamily: _font,
            fontSize: '4rem',
            color: _textColor,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    boxShadow: 'none',
                }
            }
        },
        MuiButton: {
            variants: [{
                props: {variant: 'bold'},
                style: {
                    backgroundColor: _accentColor,
                    color: 'white',
                    fontFamily: _font,
                    fontSize: '2rem',
                    '&:hover': {
                        backgroundColor: '#D45168',
                    },
                }
            }],
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    colorPrimary: _backgroundColor,
                }
            }
        }
    },
});

export const auth_theme = createTheme(theme, {
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: _backgroundColor
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        color: '#411405',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgba(0, 0, 0, 0.5)',
                        }
                    },
                }
            }
        }
    },
})