import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import {Grid, Box, Card, CardActions, CardContent, Button, Typography, CardMedia} from '@mui/material';

import Footer from '../components/Footer.jsx';
import Popup from '../components/Popup.jsx';

const slider_images = [
    {url: 'images/imag1.png'},
    {url: 'images/imag2.png'},
    {url: 'images/imag3.png'},
];

const trends = [
    {
        header: 'Knitwear', 
        image: 'images/lt1.png',
        body: 'Some Spring/Summer 2002 fashion week guests wore green shoes, many also incorporated green into other areas of their outfits, especially accessories like scarves and handbags. Knitwear like scarves, hats, and sweaters also made popular finishing touches to many of the street wear styles and are perfect for transitioning from winter to spring.',
        source: 'Forbes',
        link: '',
    },
    {
         header: 'Baguette "Hobo" Bag',
         image: 'images/lt2.jpg',
         body: "A handbag silhouette that strikes the perfect balance between utility and style is the baguette hobo. A coffee and an iPhone and the sticky hand of a cute kiddo — I already have my hands full, so adding a bag into the mix is out of the question, Tomasi Hill says. 'Handsfree bags aren't just trending, they're a necessity. I love a versatile crossbody (Bottega for the freakin’ win) but a cool baguette over the shoulder is a good way to change things up.",
         source: 'TZR',
         link: '',
    }
];

const spotlights = [
    {
        header: 'What Not To Wear for an Online Interview!!',
        image: 'images/sl1.png',
        body: '',
        link: 'https://www.forbes.com/sites/ashiraprossack1/2020/04/23/what-not-to-wear-for-an-online-interview/?sh=4cfe35742124'
    },
    {
        header: 'What makes you like images on Instagram!!',
        image: 'images/sl2.jpg',
        body: '',
        link: 'https://poole.ncsu.edu/thought-leadership/article/study-ids-visual-characteristics-that-make-people-like-images-on-instagram/'
    }
];

const pages = [
    {header: 'Wellness', link: '/wellness', image: 'images/page1.png' },
    {header: 'Own Your Finances', link: '/finance', image: 'images/page2.png' },
    {header: 'Skin and Beauty', link: '/beauty', image: 'images/page3.png' },
    {header: 'Personal Growth', link: '/growth', image: 'images/page4.png' },
];

const testimonials = [
    {image: 'images/test1.png'},
    {image: 'images/test2.png'},
    {image: 'images/test3.png'},
];

const sources = [
    {image: 'images/partner/ann taylor.png'},
    {image: 'images/partner/BR_Logo_black.svg'},
    {image: 'images/partner/H&M.png'},
    {image: 'images/partner/Hollister.png'},
    {image: 'images/partner/lulu.png'},
    {image: 'images/partner/Uniqlo.png'},
    {image: 'images/partner/zara.png'},
    {image: 'images/partner/mango.png'},
]; {/* {image: 'images/partner/gap.svg'},*/}

const partners = [
    {image: 'images/partner1.png'},
];

const POPUP_TIMEOUT = 5000;

const Home = (props) => {

    const [popupOpen, setPopupOpen] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {setPopupOpen(true)}, POPUP_TIMEOUT);
    // }, []);

    return (
        <>
        <Popup popupOpen={popupOpen} setPopupOpen={setPopupOpen}/>

        <Carousel interval={3000}>
            {slider_images.map((image) => (
                <img 
                    alt='banners'
                    src={image.url}
                    style={{
                        width: '100%',
                        objectFit: 'cover',
                        minHeight: '225px',
                        maxHeight: '1000px',
                        height: '28vw',
                    }}
                />
            ))}
        </Carousel>

        <Grid container columnSpacing={6} px={{xs:3, lg:25}} py={2} my={0} sx={{bgcolor: '#FFFFFF'}}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant='h4' sx={{ typography: { xs: 'bodyh1', lg: 'bodyh23'} }}>Latest Trends</Typography>
                <Carousel interval={5000}>
                    {trends.map((trend) => (
                        <CustomCard_LatTrends header={trend.header} image={{url:trend.image}} body={trend.body} source={trend.source} />
                    ))}
                </Carousel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography variant='h4' sx={{ typography: { xs: 'bodyh1', lg: 'bodyh23'} }}>Spotlight</Typography>
                <Carousel interval={4000}>
                    {spotlights.map((spotlight) => (
                        <CustomCard_SpLight header={spotlight.header} image={{url:spotlight.image}} body={spotlight.body} action={{text: 'View More', link: spotlight.link}}/>
                    ))}
                </Carousel>
            </Grid>
            {/*<Grid item xs={12} pb={3} sx={{textAlign: 'center'}}>
                <GetStartedButton />
            </Grid>*/}
        </Grid>

        <Grid container columnSpacing={6} px={{xs:3, lg:25}} my={0} sx={{bgcolor: '#ECDAC4'}}>
            <Grid item xs={12} py={2}>
                <Typography variant='h4' sx={{ typography: { xs: 'bodyh1', lg: 'bodyh23'} }}>Inspired by You, Styled by Us</Typography>
            </Grid>
            <Grid item xs={12} sm={4} pb={2}>
                <CustomCardi justifyContent='center' image={{url:'images/iysu1.png', padding: true}} body='We are a purpose built platform and want to understand your style, personal preferences and help you reach your goals.'/>
            </Grid>
            <Grid item xs={12} sm={4} pb={2}>
                <CustomCardi image={{url: 'images/iysu2.png', padding: true}} body='AI/ML technology intersects with internationally acclaimed fashion stylists to recommend curated looks just for you instantaneously.'/>
            </Grid>
            <Grid item xs={12} sm={4} pb={2}>
                <CustomCardi image={{url: 'images/iysu3.png', padding: true}} body='Feel confident, and celebrate yourself!!'/>
            </Grid>
            <Grid item xs={12} pb={2} sx={{textAlign: 'center'}}>
                <GetStartedButton />
            </Grid>
        </Grid>

        <Grid container columnSpacing={6} px={{xs:3, lg:25}} my={0} justifyContent='center' sx={{bgcolor: '#FFFFFF'}}>
            {pages.map((page) => (
                <Grid item xs={12} lg={3} py={2}>
                    <CustomCard1 header={page.header} image={{url: page.image}} action={{text: 'View More', link: page.link}}/>
                </Grid>
            ))}
        </Grid>

        <Grid container columnSpacing={10} px={{xs:3, lg:25}} my={0} sx={{bgcolor: '#ECDAC4'}}>
            <Grid item xs={12} py={2}>
                <Typography variant='h4' sx={{ typography: { xs: 'bodyh1', lg: 'bodyh23'}, mx:1 }}>Testimonials</Typography>
            </Grid>
            {testimonials.map((testimonial) => (
                <Grid item xs={12} lg={4} pb={2}>
                    <CustomCard2 image={{url:testimonial.image}}/>
                </Grid>
            ))}
        </Grid>

        <Grid container columnSpacing={2} px={{xs:3, lg:25}} py={{xs:2, lg:3}} my={0} alignItems='center' sx={{bgcolor: 'white'}}>
            <Grid item xs={12}>
                <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh23'}, mx:1 }}>Our Partners</Typography>
            </Grid>
            {partners.map((partner) => (
               <Grid item xs={12} lg={3} sx={{textAlign: 'center'}}>
                    <img
                        src={partner.image}
                        style={{
                            maxHeight: '9rem',
                            maxWidth: '40rem'
                        }}
                        alt=''
                    />
                </Grid>
            ))}
        </Grid>

        <Grid container columnSpacing={2} px={{xs:3, lg:25}} py={{xs:2, lg:3}} my={0} justifyContent='center' alignItems='center' sx={{bgcolor: 'white'}}>
            <Grid item xs={12}>
                <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh23'}, mx:1 }}>Sourced For You</Typography>
            </Grid>
            {sources.map((source) => (
               <Grid item xs={12} lg={3} sx={{textAlign: 'center'}}>
                    <img
                        src={source.image}
                        style={{
                            maxHeight: {xs:'2rem', lg:'3rem'},
                            maxWidth: '100%'
                        }}
                        alt=''
                    />
               </Grid>
            ))}
        </Grid>

        <Footer />
        </>
    );
}

export default Home;

function GetStartedButton() {
  const navigate = useNavigate()
  return (
      <Button
          size= 'large'
          variant='bold'
          sx={{ mt: 6, fontSize: {xs:'15px', lg:'30px'}}}
          onClick={() => navigate('/StyleQuiz')}
          style= {{
                 backgroundColor: '#411405',
                 color: 'white',
                 textTransform: 'none',
                 fontSize: '1.5rem',
                 '&:hover': {
                     backgroundColor: '#D45168',
                 },
          }}
          >
          Get Started
      </Button>
  );
}

const CustomCard_SpLight = (props) => {
    return (
        <Card sx={{ height: {xs: '450px', lg:'650px'}}}>
            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    height= '70%'
                    image={props.image.url}
                />
            : null}

            <CardContent style={{height:'15%'}}>
                {props.hasOwnProperty('header') ?
                    <Typography sx={{ typography: { xs: 'bodyh1', md: 'bodyh2'} }}>
                        {props.header}
                    </Typography>
                : null}
                {props.hasOwnProperty('body') ?
                    <Typography sx={{ typography: { xs: 'body07', lg: 'body18'} }}>
                        {props.body}
                    </Typography>
                : null}
            </CardContent>

            {props.hasOwnProperty('action') ?
                    <CardActions style={{height:'15%'}}>
                    <Button
                        sx={{color: '#C82D48', fontWeight: 'bold', typography: { xs: 'bodyl1', lg: 'bodyl18' }}}
                        style={{textTransform: 'none'}}
                        href={props.action.link} target='_blank' rel='noopener'
                    >
                        {props.action.text}
                    </Button>
                </CardActions>
            : null}
        </Card>
    );
}

const CustomCard_LatTrends = (props) => {
    return (
        <Card sx={{ height: {xs: '450px', lg:'650px'}}}>
            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    height='50%'
                    image={props.image.url}
                />
            : null}

            <CardContent
                style={{height:'50%'}}
            >
                {props.hasOwnProperty('header') ? 
                    <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh2'} }}>
                        {props.header}
                    </Typography>
                : null}
                {props.hasOwnProperty('body') ? 
                    <Typography height='70%' sx={{ typography: { xs: 'body07', lg: 'body13'}, overflowY: 'scroll'}}>
                        {props.body}
                    </Typography>
                : null}
                {props.hasOwnProperty('source') ? 
                    <Typography sx={{ typography: { xs: 'bodyl1', lg: 'bodyl18'} }}>
                       (Source: {props.source})
                    </Typography>
                : null}
            </CardContent>
        </Card>
    );
}

const CustomCard1 = (props) => {
    return (
        <Card sx={{height:'100%', bgcolor:'#FFFFFF'}}>
            <CardContent>
                {props.hasOwnProperty('header') ?
                    <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh2'}  }}>
                        {props.header}
                    </Typography>
                : null}
                {props.hasOwnProperty('body') ?
                    <Typography sx={{ typography: { xs: 'body07', lg: 'body13'} }}>
                        {props.body}
                    </Typography>
                : null}
            </CardContent>

            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    height= {{xs: '300px', lg: '450px'}}
                    sx={!props.image.hasOwnProperty('padding') || props.image.padding !== false ?
                            {p: 1, borderRadius: '1px'}
                        :
                            {}}
                    image={props.image.url}
                />
            : null}

            {props.hasOwnProperty('action') ?
                <CardActions>
                    <Button
                        sx={{color: '#C82D48', typography: { xs: 'bodyl1', lg: 'bodyl18' }}}
                        style={{textTransform: 'none'}}
                        href={props.action.link}
                    >
                        {props.action.text}
                    </Button>
                </CardActions>
            : null}
        </Card>
    );
}

const CustomCard2 = (props) => {
    return (
        <Card sx={{ height: {xs: '260px', lg: '350px'}}}>
            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    image={props.image.url}
                    sx={{p: 3, borderRadius: '3px'}}
                />
            : null}

            <CardContent>
                {props.hasOwnProperty('header') ?
                    <Typography variant='h3'>
                        {props.header}
                    </Typography>
                : null}
                {props.hasOwnProperty('body') ?
                    <Typography variant='h5'>
                        {props.body}
                    </Typography>
                : null}
            </CardContent>

            {props.hasOwnProperty('action') ?
                <CardActions>
                    <Button
                        sx={{color: '#C82D48'}}
                        href={props.action.link}
                    >
                        {props.action.text}
                    </Button>
                </CardActions>
            : null}
        </Card>
    );
}

const CustomCardi = (props) => {
    return (
        <Card sx={{ height: {xs: '300px', lg: '500px'}}}>
            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    height = '75%'
                    sx={{p: 0.5, borderRadius: '1px'}}
                    image={props.image.url}
                />
            : null}

            <CardContent>
                {props.hasOwnProperty('body') ?
                    <Typography sx={{ typography: { xs: 'body09', lg: 'body13' } }}>
                        {props.body}
                    </Typography>
                : null}
            </CardContent>
        </Card>
    );
}