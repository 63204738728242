import React from 'react';
import {Grid, Card, CardActions, CardContent, Button, Typography, CardMedia} from '@mui/material';

const wellness = [
    {image: 'images/ComingSoon.png'},
];


const Wellness = () => {
    return (
        <>
        <Grid container columnSpacing={10} rowSpacing={2} px={{xs:3, lg:45}} pb={{xs:2, lg:5}} direction="row" alignItems="center" sx={{bgcolor: '#FFFFFF'}}>
            <Grid item xs={12} pb={5}>
                {wellness.map((com) => (
                    <CustomCard image={{url:com.image}} />
                ))}
            </Grid>
        </Grid>
        </>
    );
}
export default Wellness;

const CustomCard = (props) => {
    return (
        <Card sx={{ height: '1100px'}}>
            {props.hasOwnProperty('image') ?
                <CardMedia
                    component='img'
                    height='1100px'
                    sx={!props.image.hasOwnProperty('padding') || props.image.padding !== false ?
                            {p: 1, borderRadius: '1px'}
                        :
                            {}}
                    image={props.image.url}
                />
            : null}

            <CardContent>
                {props.hasOwnProperty('header') ?
                    <Typography variant='h4'>
                        {props.header}
                    </Typography>
                : null}
                {props.hasOwnProperty('body') ?
                    <Typography variant='body'>
                        {props.body}
                    </Typography>
                : null}
            </CardContent>

            {props.hasOwnProperty('action') ?
                <CardActions px='5'>
                    <Button
                        sx={{color: '#C82D48', fontWeight: 'bold'}}
                        href={props.action.link}
                    >
                        {props.action.text}
                    </Button>
                </CardActions>
            : null}
        </Card>
    );
}