import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Card, Box, TextField, Button, Typography, Link} from '@mui/material';
import {Auth} from 'aws-amplify';

const ResetPassword = (props) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        emailOrPhoneNumber: '',
    })

    const [message, setMessage] = useState({
        success: null, 
        text: ""
    });

    const handleChange = async (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const phone_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (props.user) {
        navigate('/')
    }

    const _resetPassword = async (event) => {
        event.preventDefault();
        let username = formData.emailOrPhoneNumber;
        if (phone_regex.test(formData.emailOrPhoneNumber)) {
            username = '+1' + formData.emailOrPhoneNumber.replace(/D/g, '');
        }
        Auth.forgotPassword(username).then(res => {
            console.log(res);
            setMessage({success: true, text: 'Password recovery link sent to ' + formData.emailOrPhoneNumber + '.'});
        }).catch(error => {
            console.log(error);
            setMessage({success: false, text: error.message})
        });
    }

    return (
        <>
        <Container component="main" maxWidth="sm">
            <Card
                 sx={{
                     marginTop: 10,
                     padding: 5,
                     display: 'flex',
                     flexDirection: 'column',
                     borderRadius: 2,
                     bgcolor: '#FFFFFF',
                     border: '1px solid #411405'
                 }}
            >
                <Typography variant='h5'>
                    Forgot Your Password?
                </Typography>
                <Typography mt={2} align='center'>
                    Don't worry! Just enter the Email or Phone Number you registered with iBelieve to reset your password.
                </Typography>
                <Typography mt={message.text ? 2 : 0} sx={{color: message.success ? 'green' : 'red'}} align='center'>
                    {message.text}
                </Typography>
                <Box 
                    component='form'
                    mt={1}
                    sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}
                    onSubmit={_resetPassword}
                >
                    <TextField
                        label='Email or Phone Number'
                        name='emailOrPhoneNumber'
                        margin='normal'
                        value={formData.emailOrPhoneNumber}
                        onChange={handleChange}
                        autoFocus
                        required
                        helperText=''
                    />
                    <Button
                         type='submit'
                         variant='outlined'
                         size='small'
                         fullWidth
                         alignItems='center'
                         sx={{mt:1, width:'60%'}}
                         style= {{
                             backgroundColor: '#411405',
                             color: 'white',
                             fontSize: '1rem',
                             '&:hover': {
                                 backgroundColor: '#D45168',
                             },
                         }}
                    >
                        Send Recovery Link
                    </Button>
                </Box>
                <Box mt={2} sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                    <Typography>
                        Remember your password?&nbsp;
                        <Link href="/login" sx={{color:'#C82D48'}}>
                            Login Instead
                        </Link>
                    </Typography>
                </Box>
            </Card>
        </Container>
        </>
    );
}

export default ResetPassword;