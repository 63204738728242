import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Card, Box, TextField, Button, Typography, Link, Divider} from '@mui/material';
import {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';

const Login = (props) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const [message, setMessage] = useState({
        success: null, 
        text: ""
    });

    if (props.user) {
        navigate('/')
    }

    const handleChange = async (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value});
    } // ? formData.password :'D3f@ult!'

    const _login = async (event) => {
        event.preventDefault();
        Auth.signIn({
            username: formData.email,
            password: formData.password,
        }).then(res => {
            setMessage({success: true, text: 'Logged In!'});
        props.updateUser();
        navigate('/');
        }).catch(error => {
            setMessage({success: false, text: error.message})
        })
    }

    const _federatedLogin = async (provider) => {
        Auth.federatedSignIn({provider: provider});
    }

    return (
        <>
        <Container component="main" maxWidth="sm" justifyContent='center'>
            <Card
                sx={{
                    marginTop: 10,
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 2,
                    bgcolor: '#FFFFFF',
                    border: '1px solid #411405'
                }}
            >
                <Typography sx={{ typography: { xs: 'body', lg: 'body13' }}}>Sign In</Typography>
                <Typography mt={message.text ? 2 : 0} sx={{color: message.success ? 'green' : '#C82D48'}} align='center'>
                    {message.text}
                </Typography>
                <Box 
                    component='form' 
                    mt={1} 
                    onSubmit={_login}
                    justifyContent='center'
                >
                    <TextField
                        label='Email'
                        name='email'
                        margin='normal'
                        value={formData.email}
                        onChange={handleChange}
                        autoFocus
                        required
                    />
                    <TextField
                        label='Password'
                        name='password'
                        margin='normal'
                        value={formData.password}
                        onChange={handleChange}
                        type='password'
                        required
                    />{<br/>}
                    <Box mt={2} sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                    <Button
                        type='submit'
                        variant='outlined'
                        size='small'
                        fullWidth
                        alignItems='center'
                        sx={{mt:1, width:'30%'}}
                        style= {{
                            backgroundColor: '#411405',
                            color: 'white',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#D45168',
                            },
                        }}
                    >
                    Sign In
                    </Button>
                    {<br/>}
                    <Typography >
                        <Link href="/resetpassword" sx={{color:'#C82D48'}}>
                            Forgot your password?&nbsp;
                        </Link>
                    </Typography>
                    </Box>
                </Box>
                {<br/>}
                <Divider >
                <Typography
                    variant='body'
                    mt={1}
                    sx={{ typography: { xs: 'bodyh1', lg: 'bodyh1'}, mx:1 }}
                >
                    Or
                </Typography>
                </Divider>
                <Box mt={2} sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}
                >
                    <Button
                        variant='outlined'
                        size='small'
                        fullWidth
                        onClick={() => {_federatedLogin(CognitoHostedUIIdentityProvider.Google);}}
                        sx={{mt:1, width:'60%'}}
                        style= {{
                            backgroundColor: '#411405',
                            color: 'white',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#D45168',
                            },
                        }}
                        startIcon={<GoogleIcon sx={{ color: '#FFFFFF'}} />}
                    >
                        Login with Google 
                    </Button>
                    <Button
                        variant='outlined'
                        size='small'
                        fullWidth
                        onClick={() => {_federatedLogin(CognitoHostedUIIdentityProvider.Facebook);}}
                        sx={{mt:1, width:'60%'}}
                        style= {{
                            backgroundColor: '#411405',
                            color: 'white',
                            fontSize: '1rem',
                            '&:hover': {
                                backgroundColor: '#D45168',
                            },
                        }}
                        startIcon={<FacebookIcon sx={{ color: '#FFFFFF'}}/>}
                    >
                        Login with Facebook 
                    </Button>
                </Box>
                <Box mt={2} sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                    <Typography>
                        Don't have an account?&nbsp;
                        <Link href="/signup" sx={{color:'#C82D48'}}>
                            Create One
                        </Link>
                    </Typography>
                </Box>
            </Card>
        </Container>
        </>
    );
}

export default Login;