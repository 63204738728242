import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Grid, Card, Box, TextField, Button, Typography, FormControlLabel, Checkbox, Link} from '@mui/material';
import {Auth} from 'aws-amplify';

const Signup = (props) => {
    const navigate = useNavigate();

    const emptyFormData = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthdate: '',
        password: '',
        confirmPassword: '',
    }

    const [formData, setFormData] = useState(emptyFormData);

    const [validity, setValidity] = useState({
        email: true,
        phoneNumber: true,
        password: true,
        confirmPassword: true,
    })

    const [message, setMessage] = useState({
        success: null, 
        text: ""
    });

    if (props.user) {
        navigate('/')
    }

    const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
    const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phone_regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    const handleChange = async (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value});
        validateInput(e);
    }
    
    const validateInput = async (e) => {
        if (e.target.name === 'password') {
            setValidity({...validity, password: password_regex.test(e.target.value), confirmPassword: e.target.value === formData.confirmPassword});
        } else if (e.target.name === 'confirmPassword') {
            setValidity({...validity, confirmPassword: e.target.value === formData.password});
        } else if (e.target.name === 'email') {
            setValidity({...validity, email: email_regex.test(e.target.value)});
        } else if (e.target.name === 'phoneNumber') {
            setValidity({...validity, phoneNumber: phone_regex.test(e.target.value)});
        }
    }

    const _signup = async (event) => {
        event.preventDefault();
        if (Object.values(validity).every(x => x === true)) {
            Auth.signUp({
                username: formData.email,
                password: formData.password,
                attributes: {
                    name: formData.firstName + ' ' + formData.lastName,
                    phone_number: '+1' + formData.phoneNumber.replace(/D/g, ''),
                    birthdate: formData.birthdate
                }
            }).then(res => {
                setMessage({success: true, text: 'Account Created! ' + formData.email + '.'});
                setFormData(emptyFormData)
                props.updateUser();
                navigate('/');
            }).catch(error => {
                console.log(error)
                setMessage({success: false, text: error.message})
            });
        }
    };

    return (
        <>
        <Container component="main" maxWidth="sm">
            <Card
                  sx={{
                      marginTop: 10,
                      padding: 5,
                      display: 'flex',
                      flexDirection: 'column',
                      borderRadius: 2,
                      bgcolor: '#FFFFFF',
                      border: '1px solid #411405'
                  }}
             >
                <Typography sx={{ typography: { xs:'body', lg:'bodyh2' }}}>
                    Create Account
                </Typography>
                <Typography mt={message.text ? 2 : 0} sx={{color: message.success ? 'green' : 'red'}} align='center'>
                    {message.text}
                </Typography>
                <Box 
                    component='form' 
                    mt={1}
                    sx={{display:'flex', flexDirection:'column', alignitems: 'center'}}
                    onSubmit={_signup}
                >
                    <Grid container columnSpacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='First Name'
                                name='firstName'
                                margin='normal'
                                value={formData.firstName}
                                onChange={handleChange}
                                autoFocus
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label='Last Name'
                                name='lastName'
                                margin='normal'
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </Grid>

                    <TextField
                        label='Email'   
                        name='email'
                        margin='normal'
                        value={formData.email}
                        onChange={handleChange}
                        error={!validity.email}
                        helperText={!validity.email ? 'Invalid Email' : ''}
                        required
                    />
                    <TextField
                        label='Phone Number'   
                        name='phoneNumber'
                        margin='normal'
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        error={!validity.phoneNumber}
                        helperText={!validity.phoneNumber ? 'Invalid Phone Number' : ''}
                        required
                    />
                    {/*<TextField
                        label='Birthday'
                        name='birthdate'
                        margin='normal'
                        type='date'
                        value={formData.birthdate}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />*/}
                    <TextField
                        label='Password'
                        name='password'
                        margin='normal'
                        type='password'
                        value={formData.password}
                        onChange={handleChange}
                        error={!validity.password}
                        helperText={!validity.password ? 'Must contain 8 characters, Uppercase Letter, Lowercase Letter, Number, and Special Character' : ''}
                        required
                    />
                    <TextField
                        label='Confirm Password'
                        name='confirmPassword'
                        margin='normal'
                        type='password'
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={validity.password && !validity.confirmPassword}
                        helperText={validity.password && !validity.confirmPassword ? "Passwords Must Match" : ''}
                        required
                    />
                    <FormControlLabel
                        control={<Checkbox value="terms" required/>}
                        label="I agree to Terms & Conditions"
                        sx={{my:1, typography: { xs: 'bodyh1', lg: 'bodyh1'}}}
                    />
                    <Button
                         type='submit'
                         variant='outlined'
                         size='small'
                         fullWidth
                         alignitems='center'
                         sx={{mt:1, width:'60%'}}
                         style= {{
                             backgroundColor: '#411405',
                             color: 'white',
                             fontSize: '1rem',
                             '&:hover': {
                                 backgroundColor: '#D45168',
                             },
                         }}
                    >
                        Create Account
                    </Button>
                </Box>
                <Box mt={2} sx={{display:'flex', flexDirection:'column', alignitems: 'center'}}>
                    <Typography>
                        Already have an Account?&nbsp;
                        <Link href="/login" sx={{color:'#C82D48'}}>
                        Login Instead
                        </Link>
                    </Typography>
                </Box>
            </Card>
        </Container>
        </>
    );
}

export default Signup;