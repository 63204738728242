import React, {useState} from 'react';
import {Grid, Typography, TextField, Button, Dialog, DialogTitle, IconButton, Box} from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import {useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';

const Popup = (props) => {

    const [firstName, lastName, email, setFirstName, setLastName, setEmail] = useState('');
    
    const [emailValidity, setEmailValidity] = useState({email: true});

     {/*const handleChange = async (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        setEmailValidity(email_regex.test(email));
    };

    const _submit = async (event) => {
        event.preventDefault();
        if (emailValidity) {
            props.setPopupOpen(false);
        }
    };*/}

        const navigate = useNavigate();

        const emptyFormData = {
            firstName: '',
            lastName: '',
            email: '',
        }

        const [formData, setFormData] = useState(emptyFormData);

        const [validity, setValidity] = useState({
            email: true,
        })

        const [message, setMessage] = useState({
            success: null,
            text: ""
        });

        if (props.user) {
            navigate('/')
        }

        const email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const handleChange = async (e) => {
            e.preventDefault();
            setFormData({...formData, [e.target.name]: e.target.value});
            validateInput(e);
        }

        const validateInput = async (e) => {
            if (e.target.name === 'email') {
                setValidity({...validity, email: email_regex.test(e.target.value)});
            }
        }

        const _signup = async (event) => {
            event.preventDefault();
            if (Object.values(validity).every(x => x === true)) {
                Auth.signUp({
                    username: formData.email,
                    password: "D3f@ult!",
                    attributes: {
                        name: formData.firstName + ' ' + formData.lastName
                    }
                }).then(res => {
                    setMessage({success: true, text: 'Account Created! Verification link sent to ' + formData.email + '.'});
                    setFormData(emptyFormData)
                    props.updateUser();
                }).catch(error => {
                    console.log(error)
                    setMessage({success: false, text: error.message})
                });
            }
            props.setPopupOpen(false);
        };

    return (
        <Dialog 
            open={props.popupOpen} 
            onClose={() => props.setPopupOpen(false)}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundImage: 'url("' + 'images/Subscribe_Popup.png' + '")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    maxWidth: '700px',
                    //maxHeight: '650px',
                }
            }}
        >
        <Box
            component='form'
            mt={1}
            sx={{display:'flex', flexDirection:'column', alignItems: 'center'}}
            onSubmit={_signup}
            justifyContent='center'
        >
            <Grid container pt={1} pr={1}>
                <Grid item xs={12} textAlign='right'>
                    <IconButton aria-label="close" onClick={() => props.setPopupOpen(false)}>
                        <CloseIcon style={{fontSize: '2.2rem', color:'#411405' }}/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container px={{xs:3, lg:5}} pb={{xs:3, lg:5}} rowSpacing={4} columnSpacing={1}
                    //sx={{bgcolor: '#FFFFFF',border: '1px solid #411405'}}
                >
                <Grid item xs={12} textAlign='center'>
                    <Typography sx={{ typography: { xs: 'bodyl1', lg: 'bodyl23' }}}>Welcome to the Community!</Typography>
                </Grid>
                <Grid item xs={12} textAlign='center'>
                    <Typography sx={{ typography: { xs:'body09',lg:'body13'}}} px={{xs:3}}>
                        We promise to keep you in the loop{<br/>}{<br/>}
                    </Typography>
                </Grid>
                <Grid container alignItems='center' columnSpacing={3}>
                    <Grid item xs={12} lg={6} alignItems='center'>
                        <TextField
                            label='First Name'
                            name='firstName'
                            margin='normal'
                            value={formData.firstName}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'white'
                                }
                            }}
                            onChange={handleChange}
                            fullWidth
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} alignItems='center'>
                        <TextField
                            label='Last Name'
                            name='lastName'
                            margin='normal'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'white'
                                }
                            }}
                            value={formData.lastName}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Email'
                            name='email'
                            margin='normal'
                            value={formData.email}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'white'
                                }
                            }}
                            onChange={handleChange}
                            error={!validity.email}
                            helperText={!emailValidity ? 'Invalid Email' : ' '}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            size='small'
                            variant='bold'
                            sx={{fontSize: {xs:'10px', lg:'15px'}}}
                            onClick={_signup}
                            style= {{
                                 backgroundColor: '#411405',
                                 color: 'white',
                                 textTransform: 'none',
                                 fontSize: '1rem',
                                 '&:hover': {
                                     backgroundColor: '#D45168',
                                 },
                             }}
                            >
                            Count Me In
                        </Button>
                    </Grid>
                    <Grid item xs={12} py={1}>
                         <Typography sx={{ typography: { xs:'body07',lg:'body'}}}>
                                  We believe in no spam
                         </Typography>
                    </Grid>
                </Grid>
            </Grid>
            </Box>
      </Dialog>
    );
}

export default Popup;