import React from 'react';

import {Grid, Stack, Typography, IconButton, Link} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    
    return (
        <Grid container rowSpacing={1} py={3} px={6} sx={{bgcolor: '#ECDAC4'}}>
            <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                    <Link href='/aboutUs' underline="none">
                        <Typography variant="h5">About Us</Typography>
                    </Link>
                    <Link href='/privPolicy' underline="none">
                    <Typography variant="h5">Privacy Policy</Typography>
                    </Link>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Stack sx={{alignItems: {sm: 'end'}}}>
                    <Typography variant="body" px={{sm: 2}}>Follow Us:</Typography>
                    <Grid container justifyContent={{sm: 'right'}}>
                        <IconButton href='' >
                            <FacebookIcon style={{fontSize: '2.5rem', color:'#411405' }}/>
                        </IconButton>
                        <IconButton href='' >
                            <InstagramIcon style={{fontSize: '2.5rem', color:'#411405' }}/>                        
                        </IconButton>
                    </Grid>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default Footer;