import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amplify, {Auth} from 'aws-amplify'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { theme, auth_theme } from './theme'
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import SocialImpact from './pages/SocialImpact';
import StyleQuiz from './pages/StyleQuiz';
import Community from './pages/Community';
import Wellness from './pages/Wellness';
import Finance from './pages/Finance';
import Beauty from './pages/Beauty';
import Growth from './pages/Growth';
import AboutUs from './pages/AboutUs';
import PrivPolicy from './pages/PrivPolicy';
import ContactUs from './pages/ContactUs';

Amplify.configure({
    Auth: {
        region: 'us-west-1',
        userPoolId: 'us-west-1_mxTyx7yF1',
        userPoolWebClientId: '2as125god7th7fmcrdsebe0tos',
        mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        oauth: {
            domain: 'ibelieveai.auth.us-west-1.amazoncognito.com',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
});
  
Auth.configure();

const App = () => {
    const [user, setUser] = useState(null);

    const updateUser = async () => {
        Auth.currentAuthenticatedUser().then(user => {
            setUser(user);
        }).catch(err => {
            setUser(null);
        });
    }

    useEffect(() => {
        updateUser();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div className='App'>
                <CssBaseline />
                <Navbar user={user} updateUser={setUser}/>
                <Router>
                    <Routes>
                        <Route exact path='/' element={<Home user={user} updateUser={updateUser}/>} />
                        <Route exact path='/SocialImpact' element={<SocialImpact/>} />
                        <Route exact path='/StyleQuiz' element={<StyleQuiz/>} />
                        <Route exact path='/Community' element={<Community/>} />
                        <Route exact path='/wellness' element={<Wellness/>} />
                        <Route exact path='/finance' element={<Finance/>} />
                        <Route exact path='/beauty' element={<Beauty/>} />
                        <Route exact path='/growth' element={<Growth/>} />
                        <Route exact path='/aboutUs' element={<AboutUs/>} />
                        <Route exact path='/privPolicy' element={<PrivPolicy/>} />
                        <Route exact path='/ContactUs' element={<ContactUs/>} />

                        <Route exact path='/login' element={<ThemeProvider theme={auth_theme}><Login user={user} updateUser={updateUser}/></ThemeProvider>} />
                        <Route exact path='/signup' element={<ThemeProvider theme={auth_theme}><Signup user={user} updateUser={updateUser}/></ThemeProvider>} />
                        <Route exact path='/resetpassword' element={<ThemeProvider theme={auth_theme}><ResetPassword user={user} updateUser={updateUser}/></ThemeProvider>} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;