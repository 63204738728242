import React from 'react';
import {Grid, Card, CardActions, CardContent, Button, ButtonBase, Typography, CardMedia} from '@mui/material';
import Footer from '../components/Footer.jsx';

const SocialImpact = () => {
    return (
        <>
        <img
            alt='banners'
            src={'images/socImp.jpg'}
            style={{
                width: '100%',
                objectFit: 'cover',
                minHeight: '225px',
                maxHeight: '1000px',
            }}
        />
        <Grid container direction="row">
            <Grid item xs={12} py={{xs:2, lg:7}} px={{xs:2, lg:20}} >
                <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh2' }, mx:1, fontColor:'#411405'}}>
                    We aim to provide a holistic experience to you, to help you achieve your personal goals and life objectives.
                </Typography>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={2} px={{xs:2, lg:30}} py={{xs:3}} direction="row" alignItems="center" >
                <Grid item style={{border: '1px solid #411405'}} py={{xs:2}} px={{xs:2, lg:10}}>
                    <CustomCard1 image={{url:'images/sI3.jpg'}}
                    header={'#Environmentalsustainability - Greenhouse emissions'}
                    body={'Interacting with the planet responsibly. An equilibrium between consumerist human culture and the living world. It involves making life choices that ensure an equal, if not better, way of life for future generations.'} />
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={2} px={{xs:3, lg:30}} py={{xs:3}} direction="row" alignItems="center" >
                <Grid item style={{border: '1px solid #411405'}} py={{xs:2}} px={{xs:2, lg:10}}>
                    <CustomCard2 image={{url:'images/sI4.jpg'}}
                    header={'#Economicsustainability'}
                    body={'Committed to supporting local communities and businesses. Focusing on long-term economic growth without harming environmental, social and cultural aspects of our global community by uplifting small boutiques to get an online retail presence.'} />
                </Grid>
            </Grid>
            <Grid container columnSpacing={5} rowSpacing={2} px={{xs:3, lg:30}} py={{xs:3}} direction="row" alignItems="center" >
                <Grid item style={{border: '1px solid #411405'}} py={{xs:2}} px={{xs:2, lg:10}}>
                    <CustomCard1 image={{url:'images/sI5.jpg'}}
                    header={'#Socialsustainability - #recycle #givingback'}
                    body={'Partnering with organizations to give you an opportunity to donate, as good as new formal clothes to underserved communities near you. Giving job seekers a chance to look good, feel food, all thanks to you! Contact your local chapter for more information.'}
                    partImage={{url:'images/partner1.png'}}/>
                </Grid>
            </Grid>
        </Grid>
        <Footer />
        </>
    );
}
export default SocialImpact;

const CustomCard1 = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Card sx={{ height: {xs: '150px', lg: '300px'}, width: {xs: '150px', lg: '300px'}}}>
                    {props.hasOwnProperty('image') ?
                    <CardMedia
                    component='img'
                    height='100%'
                    width='100%'
                    sx={!props.image.hasOwnProperty('padding') || props.image.padding !== false ?
                            {p: .3, borderRadius: '1px'}
                        :
                            {}}
                    image={props.image.url}
                    />
                    : null}
                </Card>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={{xs:5, lg:20}}>
                <Grid item m={{xs:'1.5rem'}}mx='auto'>
                  <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh2' }}}>
                    {props.header}
                  </Typography> {<br/>}{<br/>}
                  <Typography sx={{ typography: { xs: 'body09', lg: 'body13' }, padding: '20'}}>
                      {props.body} {<br/>}{<br/>}
                      {props.hasOwnProperty('partImage') ?
                            <CardMedia
                                component='img'
                                style={{
                                    maxHeight: '4rem',
                                    maxWidth: '30rem'
                                }}
                                image={props.partImage.url}
                            />
                        : null}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
    );
}

const CustomCard2 = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs container direction="column" spacing={{xs:5, lg:20}}>
                <Grid item m={{xs:'1.5rem'}}mx='auto'>
                  <Typography sx={{ typography: { xs: 'bodyh1', lg: 'bodyh2' }}}>
                    {props.header}
                  </Typography> {<br/>}{<br/>}
                  <Typography sx={{ typography: { xs: 'body09', lg: 'body13' }, padding: '40'}}>
                      {props.body} {<br/>}{<br/>}
                  </Typography>
                </Grid>
              </Grid>
            <Grid item>
                <Card sx={{ height: {xs: '150px', lg: '300px'}, width: {xs: '150px', lg: '300px'}}}>
                    {props.hasOwnProperty('image') ?
                    <CardMedia
                    component='img'
                    height='100%'
                    width='100%'
                    sx={!props.image.hasOwnProperty('padding') || props.image.padding !== false ?
                            {p: .3, borderRadius: '1px'}
                        :
                            {}}
                    image={props.image.url}
                    />
                    : null}
                </Card>
             </Grid>
          </Grid>
    );
}