import React from 'react';
import {Grid, Card, CardActions, CardContent, Button, Typography, CardMedia} from '@mui/material';
import Footer from '../components/Footer.jsx';

const AboutUs = () => {
    return (
        <>
        <Grid container mx='auto' px={{xs:3, lg:20}} py={{xs:3, lg:5}}>
            <img
                 src={'images/mission/aboutus.png'}
                 style={{
                      maxHeight: {xs:200, lg:500},
                      maxWidth: {xs:200, lg:500},
                 }}
                 height= '100%'
                 width= '100%'
                 alt=''
            />
         </Grid>
        <Footer />
    </>
    );
}
export default AboutUs;