import React from 'react';
import {TextField, Button, Grid, CardContent, Card, Typography} from '@mui/material';
import Footer from '../components/Footer.jsx';
import { useNavigate } from 'react-router-dom'

const ContactUs = () =>
{
  return (
       <>
       <Grid container px={{xs:3, lg:10}} py={{xs:3, lg:10}} alignItems="center" sx={{bgcolor: '#ECDAC4'}}>
           <Grid item>
           </Grid>
       </Grid>
       <Grid container alignItems="center" px={{xs:5, lg:15}} m={{xs:'2rem', lg:'5rem'}} mx='auto' sx={{bgcolor: '#FFFFFF'}} >
       <Card px={{xs:5, lg:15}} ><CardContent >
        <Typography sx={{ typography: { xs: 'bodyh', lg: 'bodyh3' } }}
                gutterBottom> Contact Us</Typography>
        <Typography sx={{ typography: { xs: 'bodyh07', lg: 'bodyh1' } }} gutterBottom
                component="p">Your feedback is much appreciated!. Please fill up the form with your questions or feedback.
                We will get back to you as soon as we can.</Typography>
        <form >
             <Grid container alignItems="center" rowSpacing={3} px={{xs:5, lg:30}} py={{xs:3, lg:10}} sx={{bgcolor: '#FFFFFF'}}>
                  <Grid item xs={8} >
                    <TextField inputProps={{style: {fontSize: 30}}} InputLabelProps={{style: {fontSize: 30}}} name="fullName"
                            label="Full Name" variant="outlined" placeholder="Please enter your name!" fullWidth required/>
                  </Grid>
                  <Grid item xs={8} >
                    <TextField inputProps={{style: {fontSize: 30}}} InputLabelProps={{style: {fontSize: 30}}} name="email" type="email"
                            label="Email" variant="outlined" placeholder="Please enter your email!" fullWidth required/>
                  </Grid>
                  <Grid item xs={8} >
                    <TextField inputProps={{style: {fontSize: 30}}} InputLabelProps={{style: {fontSize: 30}}} name="message"
                            multiline rows={10} label="Message" variant="outlined" placeholder="Please enter your message!" fullWidth required/>
                  </Grid>
                  <Grid item xs={12}>
                      <SubmitButton/>
                  </Grid>
             </Grid>
        </form>
       </CardContent></Card></Grid>
       <Footer />
       </>
  );
}
export default ContactUs;

function SubmitButton() {
  const navigate = useNavigate()
  return (
      <Button
          size= 'large'
          variant='bold'
          sx={{ mx:3, mt: 6, fontSize: {xs:'10px', lg:'30px'}}}
          style= {{
               backgroundColor: '#411405',
               color: 'white',
               textTransform: 'none',
               fontSize: '1.5rem',
               '&:hover': {
                   backgroundColor: '#D45168',
               },
          }}
          onClick={() => navigate('/')}>
          Submit
      </Button>
  );
}