import React from 'react';
import {Grid, Box, Card, CardContent, Typography, CardMedia} from '@mui/material';

const QuizSlide = (props) => {

    const handleChange = async (choiceID) => {
        props.setQuestionData({...props.questionData, [choiceID]: !props.questionData[choiceID]})
    }

    return (
        <Box>
            <Typography sx={{ typography: { xs:'body', lg:'bodyh2' }}}>{props.question.header}</Typography>
            <Typography sx={{ typography: { xs:'body09', lg:'body13' }}}>{props.question.subheader}</Typography>
            <Grid container spacing={6} mt={0} sx={{justifyContent: 'center'}}>
                {props.question.choices.map((choice) => (
                    <Grid item xs={12} sm={6} md={4} lg={2.5}>
                            <Card
                                sx={{alignItems: 'center',
                                     textAlign: 'center',
                                     p: {xs: 0.5, sm: 1},
                                     backgroundColor: props.questionData[choice._id] ? '#ECDAC4': '',
                                     height: {xs: '100px', lg: '120px'},
                                     width: {xs: '100px', lg: '120px'}}}
                                onClick={() => handleChange(choice._id)}
                            >

                            <CardMedia
                                alignItems="center"
                                component="img"
                                style={{width: '65%'}}
                                image={'images/style_quiz/test.png'}
                                // image={choice.image}
                            />
                            <CardContent>
                                <Typography variant='body'>
                                    {choice.text}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default QuizSlide;